// Hardware acceleration and anti-aliasing mixins - these are based on Hover.css library
@mixin hardware-acceleration() {
  transform: perspective(1px) translateZ(0);
  -webkit-transform: perspective(1px) translateZ(0);
}
@mixin improve-anti-alias() {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

@mixin discount-badge-base {
  position: absolute;
  z-index: 5;

  border-radius: 12px;

  background: $primary;
  color: #fff !important;
  font-weight: bold;

  will-change: transform;
  transform-origin: center;

  @include hardware-acceleration();
  @include improve-anti-alias();

  .text-content {
    display: inline-block;
  }
}

@keyframes textTransition {
  0% {
    transform: scale(0.85);
  }
  60% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pricingBadgeGrowRotate {
  0% {
    transform: scale(0.8) rotate(5deg);
  }
  40% {
    transform: scale(1.25) rotate(-2deg);
  }
  65% {
    transform: scale(0.98) rotate(1deg);
  }
  85% {
    transform: scale(1.05) rotate(0);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

@keyframes checkoutDiscountBadgeGrowRotate {
  0% {
    transform: scale(0) rotate(5deg);
    opacity: 0;
  }
  50% {
    transform: scale(1.15) rotate(3deg);
    opacity: 1;
  }
  70% {
    transform: scale(0.95) rotate(-2deg);
  }
  85% {
    transform: scale(1.05) rotate(1deg);
  }
  100% {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
}
