@import "animate.css";

@import "./bulma_config.scss";
@import "~bulma";
@import "./bulma_ext.scss";

// carousel
@import "@glidejs/glide/src/assets/sass/glide.core";

/* layout-related */
@import "./alerts.scss";
@import "./allergen_list.scss";
@import "./animations.scss";
@import "./currency_change.scss";
@import "./fonts.scss";
@import "./form.scss";
@import "./growing_textarea.scss";
@import "./ingredient_list.scss";
@import "./ingredients_show.scss";
@import "./mini_testimonial.scss";
@import "./show_password_button.scss";
@import "./nutrition.scss";
@import "./nutrition_breakdown.scss";
@import "./olark_facade.scss";
@import "./stashed_file.scss";
@import "./stripe.scss";
@import "./subnavs.scss";
@import "./third_party.scss";
@import "./three_panel.scss";
@import "./undo.scss";
@import "./video.scss";
@import "./polyfill.scss";
@import "./hint.scss";
@import "./scroll_hint.scss";
@import "./tablesort.scss";
@import "./pagination.scss";
@import "./voting.scss";
@import "./settings.scss";
@import "./mobile_floating_button.scss";
@import "./subscriptions.scss";

@import "./print.scss";

.title {
  font-weight: bold;
  font-family: $family-secondary;
}

.subtitle {
  font-weight: bold;
  font-family: $family-sans-serif;
}

/* Top-level navbar menu item styling */
nav.navbar .navbar-toplevel {
  font-weight: bold;
  margin-right: 1em;
  color: $black;

  /* Bulma down-arrow is implemented in pixels in ::after */
  &.navbar-link::after {
    border-color: $grey;
  }

  &:hover,
  &:active {
    color: $dark;
  }

  position: relative;
}

nav.navbar {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;

  @include until($desktop) {
    padding-bottom: 0.125rem !important;
  }
}

.navbar-brand {
  @include desktop {
    margin-left: -1.275rem !important;
  }
}

.has-dropdown a.navbar-link:hover {
  $angle: 60deg;
  $top-offset: 45%;

  &::before {
    content: "";
    width: 100%;
    height: 120%;
    position: absolute;
    left: 0;
    top: $top-offset;
    z-index: 4;

    transform-origin: top right;
    transform: rotateZ(-$angle);
  }

  &.dropdown-is-right {
    &::before {
      transform-origin: top left;
      transform: rotateZ($angle);
    }
  }
}

nav a.navbar-item > img {
  /* Removes bulma's height restriction for brand image */
  max-height: 5rem;

  @include tablet {
    /* Only desktop+ have any other nav items to center to */
    margin-top: -10px;
  }

  @include touch {
    /* Give it a "content-like" left margin */
    margin-left: $gap div 2;
  }

  @include mobile {
    transform: translateY(-6px);
  }
}

.navbar-burger.burger {
  @include until($desktop) {
    transform: translateY(2px);
  }
}

.recipal-logo {
  @include touch {
    margin-left: 0.2325rem !important;
  }
}

.is-flex-1 {
  flex: 1;
}

/* Log In and Sign Up buttons wider */
nav .navbar-end .button {
  min-width: 8em;
}

#main-navbar {
  .navbar-start {
    @include tablet {
      margin-left: map-get($map: $spacing-values, $key: "6");
    }
  }

  .navbar-start,
  .navbar-end {
    @include touch {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .navbar-dropdown .navbar-item {
    white-space: normal;

    strong {
      display: block;
      color: $link;
      font-weight: bold;
    }

    small {
      font-size: small;
      color: $grey;
      display: block;
      margin-top: 3px;
    }

    &.is-active {
      strong {
        color: $grey;
      }
    }
  }

  .navbar-dropdown {
    min-width: 14rem;
  }

  @include desktop {
    .navbar-dropdown.products-dropdown {
      width: 20rem;
    }
  }
}

@include touch {
  .button.is-fullwidth-mobile {
    display: flex;
    width: 100%;
  }

  #main-navbar {
    a.navbar-toplevel {
      font-family: $family-secondary;
      color: $dark;

      @include touch {
        color: $black;
      }
    }

    .navbar-dropdown .navbar-item {
      padding-left: 12px;
      padding-right: 12px;

      width: 100%;
    }
  }
}

#footer {
  border-top: 2px solid lighten($grey-lighter, 10%);

  h3.subtitle {
    /* match logo SVG, and allow for descenders */
    height: 39px;
    line-height: 80%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: $grey-darker;
  }

  li {
    margin-top: map-get($spacing-values, "4");
    margin-bottom: map-get($spacing-values, "4");

    a {
      color: $grey;
    }

    &.instagram-link {
      a:hover {
        color: #e1306c;
      }
    }

    &.twitter-link {
      a:hover {
        color: #1da1f2;
      }
    }

    &.facebook-link {
      a:hover {
        color: #4267b2;
      }
    }
  }
}

@include touch {
  .tabs.collapse {
    ul {
      display: block;

      li {
        display: block;

        a {
          color: $link;
          border-bottom-color: $white;
        }

        &.is-active {
          a {
            font-weight: bold;
          }
        }
      }
    }
  }
}

input[type="range"]:not([disabled]) {
  cursor: pointer;
}

.theme-recipal {
  --fa-primary-color: #{$primary};
  --fa-secondary-color: #{$secondary};
}

#promo-banner {
  a {
    color: $white;
    text-decoration: underline;
  }
}

#account-upgrade-banner {
  // Sticky to bottom of page
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.modal-card-subhead {
  @extend .modal-card-body;
  // No padding-bottom, assumption is that .modal-card-body follows;
  // .modal-card-body's padding-top is sufficient spacing
  padding-bottom: 0;
  min-height: fit-content;
}

.modal-card-title strong {
  @extend .modal-card-title;
  font-weight: bold;
}

.is-modern > section.section {
  padding-top: 1.5rem;

  @include touch {
    padding-top: 2rem;
  }

  @include mobile {
    padding-top: 1.5rem;
  }
}

.personal-plan {
  .hide-personal {
    display: none;
  }
}
