@import "pricing_mixins";

$checkout-space-unit: 0.25rem;
$checkout-space-xs: $checkout-space-unit;
$checkout-space-sm: $checkout-space-unit * 2;
$checkout-space-md: $checkout-space-unit * 4;
$checkout-space-lg: $checkout-space-unit * 5;
$checkout-space-xl: $checkout-space-unit * 6;
$checkout-space-currency-and-cents: $checkout-space-unit * 1.5;
$pricing-box-padding: $checkout-space-lg;

$checkout-text-sm: 0.85rem;
$checkout-text-base: 1rem;
$checkout-text-lg: 1.25rem;
$checkout-text-xl: 2.5rem;
$price-letter-spacing: -0.01em;

$checkout-badge-border-radius: 16px;

$selected-badge-font-size: calc(#{$checkout-text-sm} * 1.325);
$selected-badge-padding-y: 8px;
$selected-badge-padding-x: 21px;

:root {
  --checkout-content-indent: #{$checkout-space-lg};
  --plan-name-badge-color: #{$info};

  @media screen and (min-width: $tablet) {
    --checkout-content-indent: #{$checkout-space-sm};
  }

  @media screen and (min-width: $widescreen) {
    --checkout-content-indent: #{$checkout-space-md};
  }
}

@keyframes boxSelectPulse {
  0% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes initialShimmerEffect {
  0% {
    transform: translateX(105%) skewX(-30deg);
  }
  55% {
    transform: translateX(-105%) skewX(-30deg);
  }
  55.1% {
    transform: translateX(-105%) skewX(33deg);
  }
  100% {
    transform: translateX(100%) skewX(33deg);
  }
}

@mixin single-shimmer-keyframes($name, $direction: 1) {
  @keyframes #{$name} {
    0% {
      transform: translateX(#{-105% * $direction}) skewX(-30deg);
    }
    100% {
      transform: translateX(#{105% * $direction}) skewX(-30deg);
    }
  }
}

@include single-shimmer-keyframes(hoverShimmerEffect, -1);
@include single-shimmer-keyframes(selectedPlanShimmer, 1);

@mixin shimmer-gradient {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 70%,
    transparent 100%
  );

  transform: translateX(105%) skewX(-30deg);
  pointer-events: none;
}

@mixin price-typography {
  letter-spacing: $price-letter-spacing;
}

@mixin billing-period-text-style {
  color: $grey-darker !important;
  opacity: 0.9;
  font-weight: normal;
}

.plan-name-badge {
  position: relative;

  height: calc($checkout-space-lg * 1.5);
  margin-bottom: calc($checkout-space-md * 2.5);
  @include until($desktop) {
    margin-bottom: calc($checkout-space-md * 2);
  }

  text-align: center;

  // Gradient horizontal rule behind the plan name badge
  &::before {
    content: "";
    position: absolute;
    z-index: 0;

    left: 0;
    right: 0;
    top: 50%;

    height: 1px;

    opacity: 0.5;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      var(--plan-name-badge-color) 30%,
      var(--plan-name-badge-color) 70%,
      rgba(0, 0, 0, 0)
    );
  }

  .badge-link {
    display: contents;
    text-decoration: none;
  }

  .badge {
    position: absolute;
    display: inline-block;
    z-index: 1;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    padding: $checkout-space-xs $checkout-space-md;
    border-radius: $checkout-badge-border-radius;

    background: var(--plan-name-badge-color);

    color: $white;
    font-size: $checkout-text-base;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: nowrap;
  }
}

.plan-options {
  margin-bottom: calc($checkout-space-xl * 2);
  margin-top: calc($checkout-space-xl * 2.5);
  @include until($desktop) {
    margin-bottom: calc($checkout-space-xl * 1);
    margin-top: calc($checkout-space-xl * 1.5);
  }

  & .columns .column {
    margin: 0;
    margin-bottom: $checkout-space-xs;
    @include until($desktop) {
      margin-bottom: 0;
    }

    @include desktop {
      .column:first-of-type {
        padding-left: 0 !important;
        padding-right: $checkout-space-sm !important;
      }

      .column:last-of-type {
        padding-right: 0 !important;
        padding-left: $checkout-space-sm !important;
      }

      .column {
        .pricing-box {
          height: auto;
        }
      }
    }
  }

  .pricing-box {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-items: flex-start !important;

    padding: $checkout-space-xl $checkout-space-lg $checkout-space-xl;

    height: 100%; // When the boxes are side-by-side, we want them to be equal height
    min-height: calc($checkout-space-xl * 6);
    @include until($desktop) {
      min-height: calc($checkout-space-xl * 5);
      padding: $checkout-space-md $checkout-space-lg $checkout-space-md;
    }

    border: 1px solid transparent;
    border-radius: 6px;
    outline: 1px solid $grey-light;
    outline-offset: -1px;

    transform-origin: center;
    transition:
      transform 0.3s ease,
      outline 0.2s ease,
      outline-offset 0.2s ease;
    will-change: transform, outline, outline-offset;

    // The following 4 rules are to prevent overflow at some viewport sizes
    // (namely, smaller than desktop but bigger than mobile)
    flex-grow: 0; // Don't grow beyond content size
    flex-shrink: 1;
    min-width: 0;
    width: 100%;

    // Reset anchor tag styles
    text-decoration: none;
    color: inherit;

    &:hover {
      outline: 2px solid $info;
      outline-offset: -2px;
      transform: scale(1.02);
      text-decoration: none;
      color: inherit;

      // Animates the discount-badge shimmer upon hovering the pricing box or badge itself
      .discount-badge::after {
        opacity: 1;
        animation: hoverShimmerEffect 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
      }
    }

    .pricing-box-content {
      position: relative;
      z-index: 1;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .plan-header {
      font-weight: 700;
      font-size: $checkout-text-lg;
      margin-bottom: $checkout-space-sm;
      padding-left: var(--checkout-content-indent, $checkout-space-sm);
      @include until($desktop) {
        margin-bottom: $checkout-space-xs;
      }
    }

    .plan-price {
      position: relative;
      margin-bottom: 0.75rem;
      @include until($desktop) {
        margin-bottom: 0.5rem;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      line-height: 1;
      padding-left: var(--checkout-content-indent, $checkout-space-sm);

      // Wrapper for all price content, its purpose is to center billed-annually-detail under the monthly price
      .price-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      // Wrapper for price components; we add border-bottom to it when annual plan is selected
      .yearly-per-month-price-wrapper {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        width: fit-content;

        padding-top: $checkout-space-xs;
        padding-bottom: $checkout-space-xs;
        @include until($desktop) {
          padding-bottom: $checkout-space-sm;
        }
      }

      .currency {
        @include price-typography;
        font-size: $checkout-text-base;
        font-weight: normal;
        margin-top: $checkout-space-currency-and-cents;
        margin-right: calc($checkout-space-xs / 2);
      }

      .amount {
        @include price-typography;
        font-weight: 900;
        font-size: $checkout-text-xl;
      }

      .cents {
        @include price-typography;
        display: inline-block;
        font-size: 0.7em;
        font-weight: 500;
        margin-top: $checkout-space-currency-and-cents;
        margin-left: calc($checkout-space-xs / 1.5);
      }

      .per-month {
        @include billing-period-text-style;
        @include price-typography;

        font-size: $checkout-text-base;
        white-space: nowrap;

        margin-top: $checkout-space-lg;
        margin-left: $checkout-space-xs;

        .usd {
          font-variant: small-caps;
          letter-spacing: $price-letter-spacing;
        }
      }

      .billed-annually-detail {
        @include price-typography;
        display: none; // Hidden unless this plan is selected
        max-width: 100%;
        text-align: center;

        font-weight: normal;
        font-size: $checkout-text-base;
        color: $grey-darker;

        line-height: 1.2;
        margin-top: 0.275rem;
        padding-top: calc($checkout-space-xs / 2);

        .billed-annually-text {
          @include billing-period-text-style;
          margin-left: 0.2em;
        }

        .annual-amount {
          @include price-typography;
          font-weight: 700;
          font-size: 130%;
          color: $text !important;

          .annual-currency {
            position: relative;
            top: -0.25em;
            margin-right: 0.05em;

            font-size: 0.65em;
            font-weight: 500;
          }
        }
      }
    }

    .discount-badge {
      @include discount-badge-base;
      z-index: 2;
      overflow: hidden;

      cursor: pointer;
      pointer-events: auto;

      top: -16px;
      right: -18px;
      padding: 6px 16px;
      line-height: 1.2;

      font-size: $checkout-text-sm;
      font-weight: 600;
      border-radius: $checkout-badge-border-radius;

      transition:
        transform 0.3s ease,
        filter 0.3s ease,
        font-size 0.3s ease,
        padding 0.3s ease,
        background-color 0.3s ease,
        color 0.3s ease,
        top 0.3s ease,
        right 0.3s ease;

      animation: checkoutDiscountBadgeGrowRotate 0.7s ease-out;
      animation-fill-mode: forwards;

      // Initial shimmer effect on page load
      &::before {
        @include shimmer-gradient;
        animation: initialShimmerEffect 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
      }

      // This enables the hover shimmer effect. See also pricing-box:hover further up.
      &::after {
        @include shimmer-gradient;
        opacity: 0;
        z-index: 1;
      }

      // Reposition badge on mobile/tablet to be inside the pricing-box
      @include until($desktop) {
        top: $checkout-space-xl;
        right: calc($checkout-space-md + 14px);
        margin-top: 0 !important;

        transform-origin: center;
      }

      .text-content {
        position: relative;
        z-index: 2;

        font-weight: 600;

        animation: textTransition 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
        animation-fill-mode: forwards;
        transition: transform 0.3s ease;

        @include until($desktop) {
          font-weight: 800 !important;
        }
      }
    }

    // Remove the radio button styles
    .plan-radio {
      display: none;
    }
  }

  .selected-plan {
    outline: 3px solid $info !important;
    @include until($tablet) {
      border: 3px solid $info !important;
      outline: 4px solid $info !important;
      outline-offset: -3px !important;
    }

    will-change: transform, outline;
    animation: boxSelectPulse 0.4s ease-out forwards;

    .discount-badge {
      font-size: $selected-badge-font-size;
      font-weight: 800;
      padding: $selected-badge-padding-y $selected-badge-padding-x;
      border-radius: calc(#{$checkout-badge-border-radius} * 1.2);

      top: -18px;
      right: -22px;

      margin-top: 0;
      background-color: $success;
      border-color: $success;
      color: $white !important;

      @include until($desktop) {
        top: calc(#{$checkout-space-xl} - 2px);
        right: calc(#{$checkout-space-md} + 4px);
      }

      // Selected plan has a less intense shimmer effect on page load
      &::before {
        @include shimmer-gradient;
        animation: selectedPlanShimmer 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
      }

      // No hover shimmer effect when annual plan is selected
      &::after {
        content: none;
      }
    }
  }

  .annual-column {
    .selected-plan {
      // Only when the annual plan is selected, we show the billed-annually-detail ...
      .billed-annually-detail {
        display: unset;
      }

      // ... and a thin "sum" line to visually separate the per-month price from the per-year price
      .yearly-per-month-price-wrapper {
        border-bottom: 1px solid rgba($black, 0.2);
      }
    }
  }

  @media screen and (min-width: $tablet) and (max-width: ($desktop - 1px)) {
    .columns {
      display: flex;
      flex-direction: column;
      align-items: center;

      .column {
        width: 100%;
        max-width: 500px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}
