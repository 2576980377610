@import "./form_help_container.scss";

/* Calculated based on UI Kit */
$darken-hover: 3%;
$darken-active: 10%;

.button {
  background-color: $white;

  &:hover {
    background-color: darken($white, $darken-hover);
  }
  &:active {
    background-color: darken($white, $darken-active);
  }
}

/* Generate hover and active styles for all buttons based on their primary color */
@each $variant in "primary", "success", "info", "danger", "warning" {
  .button.is-#{$variant}:not(.is-outlined):not(.is-inverted) {
    /* colors hash is color-type => [bg, fg, invbg, invfg] */
    $base-color: nth(map-get($colors, $variant), 1);
    background-color: $base-color;

    &.is-#{$variant} {
      background-color: $base-color;
      &:hover {
        background-color: darken($base-color, $darken-hover);
      }
      &:active {
        background-color: darken($base-color, $darken-active);
      }
    }
  }
}

.field {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}
/* When the last .field in flexbox is moved down to the next row
   since it is the ':last-child' it doesn't have margin which messes up spacing */
.flex-fields {
  .field {
    margin-bottom: 1rem;
  }
}

.input {
  &.is-error {
    border-color: $danger;
  }

  &.is-success {
    border-color: $success;
  }
}

input[type="date"] {
  height: 2.5em;
  text-align: center;
}

label.label {
  cursor: pointer;
}

input[type="search"]:not(:placeholder-shown)::-webkit-search-cancel-button {
  opacity: 1;
}

/* well-beneath-search-field: used for a well of settings/filters "attached" to the bottom of search field */
.well-beneath-search-field {
  @extend .well;
  margin-left: 24px;
  margin-right: 48px;
  margin-top: -6px;
}

@include until($tablet) {
  .well-beneath-search-field.responsive-no-well {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    border: none;
    background: none;
  }
}

.optional-note {
  transition: opacity 1.5s;
}

.optional-note-hidden {
  opacity: 0;
  height: 0;
  pointer-events: none;
}

.optional-note-shown {
  opacity: 1;
  height: auto;
  pointer-events: auto;
}

.click-disabled {
  pointer-events: none;
}

.half-opacity {
  opacity: 0.5;
}
