/* These styles apply to the "popup" alert views which are embedded in the layout. */
section.alerts {
  @media print {
    display: none;
  }

  .alert-parent {
    position: relative;
    z-index: 2;
  }

  .alert {
    box-shadow: $box-shadow;
    position: absolute;
    opacity: hidden;

    will-change: transform, filter;
    transition:
      transform 0.25s,
      filter;
  }

  .alert:nth-last-child(3) {
    transform: rotate(-1.5deg);
    filter: brightness(80%);
  }

  .alert:nth-last-child(2) {
    transform: rotate(2.1deg);
    filter: brightness(90%);
  }

  .alert:nth-last-child(1) {
    position: relative;
    filter: brightness(100%);
  }
}

/* Don't show alerts on the alert management page (AlertsController) */
html[data-view^="alerts#"] {
  section.alerts {
    display: none;
  }

  .container > .tabs:first-child {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
}
