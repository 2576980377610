$space-from-edge: 1rem;

#floating-component {
  position: fixed;
  bottom: $space-from-edge;
  right: $space-from-edge;
  z-index: 5;
  width: auto;
  margin: 0;

  &.is-active {
    .well {
      visibility: visible;
    }
  }

  .well {
    position: absolute;
    visibility: hidden;
    border-color: #828588;
    bottom: calc($space-from-edge + 1.5rem - 1px);
    right: 0;
    overflow: auto;
    max-height: 85vh;

    @include mobile {
      border-bottom-right-radius: 0;
    }
  }

  // On tablet size we still position it the old way rather than lower-right corner
  @include tablet {
    position: sticky;
    top: 8px;
    margin-bottom: 8px;
    bottom: auto;
    right: 0;
    display: flex;
    justify-content: flex-end;

    .well {
      position: absolute;
      top: 39px;
      bottom: auto;
      max-height: 85vh;
      border-top-right-radius: 0;
    }
  }

  .floating-button {
    z-index: 6;
    border: 1px solid $info;
    background-color: $white;
    color: $info;

    // Disable browser's focus styles
    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }

    // On mobile, make it look like a thicker border
    // but via outline not border, so it doesn't "move" when active
    @include mobile {
      &:not(.is-active),
      &:focus:not(.is-active),
      &:focus-visible:not(.is-active) {
        outline: 2px solid $info;

        &:hover {
          outline-color: $info-dark;
        }
      }
    }

    &:hover:not(.is-active) {
      background-color: $info-dark;
      border-color: $info-dark;
      color: $white;
    }

    &.is-active {
      background-color: #fafafa; // matches .well background
      color: #4c4f53;
      border-left-color: $grey-dark;
      border-right-color: $grey-dark;

      @include mobile {
        border-top-color: #fafafa; // overlaps the relevant part of the .well's border
        border-bottom-color: $grey-dark;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      @include tablet {
        border-top-color: $grey-dark;
        border-bottom-color: #fafafa;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
