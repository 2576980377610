[data-scroll-hint] {
  position: relative;
}

.table-container[data-scroll-hint] {
  /* make horizontal scrolling harder to trigger a history navigation (only relevant in some browsers) */
  overscroll-behavior-x: contain;
}

.scroll-hint-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.scroll-hint-shadow {
  position: absolute;
  top: 0;
  height: 100%;
  width: 15px;
  z-index: 10;
  pointer-events: auto;
  cursor: pointer;
  transition:
    width 0.2s ease,
    opacity 0.3s ease;

  &:hover {
    width: 35px;
  }

  &.left {
    left: 0;
    margin-left: -0.5px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }

  &.right {
    right: 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  }
}

/* Animation for shadows */
@keyframes pulseShadow {
  0% {
    width: 15px;
  }
  20% {
    width: 35px;
  }
  40% {
    width: 15px;
  }
  60% {
    width: 30px;
  }
  80% {
    width: 15px;
  }
  90% {
    width: 20px;
  }
  95% {
    width: 17px;
  }
  100% {
    width: 15px;
  }
}

/* Add pulse animation for shadows */
.scroll-hint-shadow.right.pulse-shadow {
  animation: pulseShadow 0.5s ease-out;
}
